<route>
{
  "meta": {
    "auth": "withdrawalRecord"
  }
}
</route>

<template>
  <div>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="storeName" label="申请门店"> </el-table-column>
        <el-table-column align="center" prop="money" label="已提现金额（元）" v-if="$route.query.subMchId">
        </el-table-column>
        <el-table-column align="center" prop="amount" label="提现金额（元）" v-if="!$route.query.subMchId">
        </el-table-column>
        <el-table-column align="center" prop="withdrawalCount" label="提现次数" v-if="$route.query.subMchId">
        </el-table-column>

        <el-table-column align="center" prop="operatorMemberName" label="申请人" v-if="!$route.query.subMchId">
        </el-table-column>

        <el-table-column align="center" prop="createTime" label="收款人信息">
          <template slot-scope="scope">
            <div v-if="$route.query.subMchId">
              <div>开户人姓名: {{ scope.row.bankAccountInfoVO.accountName }}</div>
              <div>收款银行: {{ scope.row.bankAccountInfoVO.accountBank }}</div>
              <div>银行账号: {{ scope.row.bankAccountInfoVO.accountNumber }}</div>
            </div>
            <div v-else>
              <div v-if="scope.row.collectionType === 0">
                微信零钱
                <div>收款人: {{ scope.row.payeeName }}</div>
                <div>手机号: {{ scope.row.payeeMobile }}</div>
              </div>
              <div v-else>
                <div>收款卡号：{{ scope.row.encBankNo }}</div>
                <div>收款人: {{ scope.row.payeeName }}</div>
                <div>手机号: {{ scope.row.payeeMobile }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" v-if="$route.query.subMchId">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="text" size="small"> 提现明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchValue: '',
      list: []
    }
  },
  methods: {
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }
      if (this.$route.query.subMchId) {
        this.$api.financial
          .financial('getWithdrawalList', params, this.$route.query.id)
          .then(res => {
            this.list = res.withdrawalRecords.records
            this.total = parseInt(res.withdrawalRecords.total)
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$http
          .get('/boom-center-statistics-service/sysAdmin/finance/withdrawal/paymentRecord', {
            params: {
              page: this.currentPage,
              size: this.pageSize,
              businessId: this.$route.query.id
            }
          })
          .then(res => {
            this.list = res.records
            this.total = parseInt(res.total)
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    check(row) {
      this.$router.push({
        path: '/financial/vendor/withdrawalDetail',
        query: {
          id: row.storeId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  div {
    line-height: 30px;

    .intro {
      display: inline-block;
      width: 100px;
      color: #999;
    }
  }
}
</style>
